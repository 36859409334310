import { IconButton, Paper } from "@material-ui/core";
import React, { Component } from "react";
import Cancel from "@material-ui/icons/Cancel";

class PhotoView extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { image } = this.props;

        return (
            <Paper style={{ position: 'relative', display: 'inline-block' }} elevation={3}>
                <img src={"data:image/jpeg;base64," + image} style={{ width: '100%', height: 'auto', display: 'block', borderRadius: 4 }} />
            </Paper>
        );
    }
}

export default PhotoView;