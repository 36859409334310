import React, { Component, Fragment } from "react";
import { CardContent, Grid, TextField, Typography } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputUtils from "../../../utils/inputUtils";
import moment from "moment";
import DeliveryStatus from "../../../constants/deliveryEnum";
import DocumentType from "../../../constants/documentsEnum";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import StubEditPhoto from "./StubEditPhoto";
import StubUpdateButton from "./StubUpdateButton";
import NotificationUtils from "../../../utils/notificationUtils";
import LoadingSpinner from "../../LoadingSpinner";

class StubEditForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stub: {
                id: props.stub.id,
                documentType: props.stub.documentType,
                issuerCnpjCpf: props.stub.issuerCnpjCpf,
                serie: props.stub.series,
                number: props.stub.number,
                issuerDate: props.stub.issueDate,
                receiverName: props.stub.receiverName,
                receiverCnpjCpf: props.stub.receiverCnpjCpf,
                accessKey: props.stub.accessKey,
                deliveryStatus: props.stub.deliveryStatus,
                observation: props.stub.observation,
                latitude: props.stub.latitude,
                longitude: props.stub.longitude,
                creationDate: props.stub.creationDate,
                creationDateTimeOnApp: props.stub.creationDateTimeOnApp,
                lastUpdateDateTime: props.stub.lastUpdateDateTime,
                lastUpdateUser: props.stub.lastUpdateUser,
                customFieldAnswers: props.stub.customFieldAnswers,
                image: props.stub.image,
                replaceImage: false
            },
            image: {
                originalImage: props.stub.image
            },
            onUpdateSucceeded: props.onUpdateSucceeded,
            onUpdateFailed: props.onUpdateFailed
        }
    }



    render() {

        const stub = this.state.stub;
        const { lastUpdateDateTime, lastUpdateUser, creationDate, creationDateTimeOnApp, documentType, issuerCnpjCpf, serie, number, issuerDate, receiverName, receiverCnpjCpf, accessKey, deliveryStatus, observation, latitude, longitude, replaceImage } = stub;
        const { onUpdateSucceeded, onUpdateFailed } = this.state;

        return (
            <Fragment>
                <Grid container md={12} spacing={2} direction="row" alignItems="center">
                    <Grid item md={3}>
                    </Grid>

                    <Grid item md={6}>
                        <h1 className='text-center'>Edição do Canhoto</h1>
                    </Grid>

                    <Grid item md={3} container justifyContent="flex-end" alignItems="center">
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                            <StubEditPhoto
                                stub={stub}
                                onPicked={(image) => this.handleImage(image, true, () => { NotificationUtils.success('Nova foto carregada.'); })}
                                onReverted={(image) => this.handleImage(image, false, () => { NotificationUtils.success('Foto original restaurada.'); })} />

                        </div>
                    </Grid>
                </Grid>

                <CardContent style={{ marginTop: 10 }}>
                    <ValidatorForm
                        noValidate>
                        <Grid container md={12} spacing={2} justify='center' direction="row">
                            <Grid item md={3}>
                                <TextField
                                    label='Tipo de Documento'
                                    select
                                    value={documentType}
                                    onChange={(e) => this.handleDocumentType(e.target.value)}
                                    title="Tipo do documento fiscal."
                                    SelectProps={{
                                        native: true,
                                    }}>
                                    <option value=''></option>
                                    {DocumentType.ALL_DOCUMENTS.map((status, index) => (
                                        <option value={status.name}>{status.description}</option>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item md={3}>
                                <TextField
                                    label='CPF/CNPJ do Emitente'
                                    value={InputUtils.formatDocument(issuerCnpjCpf)}
                                    onChange={(e) => this.handleIssuerCnpjCpf(e.target.value)}
                                    title="CPF/CNPJ do emitente do documento fiscal."
                                />
                            </Grid>

                            <Grid item md={3}>
                                <TextField
                                    label="Série"
                                    value={serie}
                                    onChange={(e) => this.handleSerie(e.target.value)}
                                    title="Série do documento fiscal."
                                    inputProps={{ maxLength: 9 }}
                                />
                            </Grid>

                            <Grid item md={3}>
                                <TextField
                                    label="Número"
                                    value={number}
                                    onChange={(e) => this.handleNumber(e.target.value)}
                                    title="Número do documento fiscal."
                                    inputProps={{ maxLength: 9 }}
                                />
                            </Grid>

                            <Grid item md={8}>
                                <TextField
                                    label="Chave de Acesso"
                                    value={accessKey}
                                    onChange={(e) => this.handleAccessKey(e.target.value)}
                                    title="Chave de acesso do documento fiscal."
                                    inputProps={{ maxLength: 44 }}
                                />
                            </Grid>

                            <Grid item md={4}>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disableFuture
                                        label='Data de Emissão'
                                        value={issuerDate}
                                        onChange={date => this.handleIssuerDate(date)}
                                        format="DD/MM/YYYY"
                                        title="Data de emissão do documento fiscal."
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item md={6}>
                                <TextField
                                    label="Nome do Recebedor"
                                    value={receiverName}
                                    onChange={(e) => this.handleReceiverName(e.target.value)}
                                    title="Nome do recebedor."
                                    inputProps={{ maxLength: 60 }}
                                />
                            </Grid>

                            <Grid item md={6}>
                                <TextField
                                    label="CPF/CNPJ do Recebedor"
                                    value={InputUtils.formatDocument(receiverCnpjCpf)}
                                    onChange={(e) => this.handleReceiverCnpjCpf(e.target.value)}
                                    title="CPF/CNPJ do recebedor."
                                />
                            </Grid>

                            <Grid item md={4}>
                                <TextField
                                    label="Latitude"
                                    value={latitude}
                                    type="number"
                                    onChange={(e) => this.handleLatitude(e.target.value)}
                                    title="Latitude (Não editável)."
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>

                            <Grid item md={4}>
                                <TextField
                                    label="Longitude"
                                    value={longitude}
                                    type="number"
                                    onChange={(e) => this.handleLongitude(e.target.value)}
                                    title="Longitude (Não editável)."
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>

                            <Grid item md={4}>
                                <TextField
                                    label='Situação da Entrega'
                                    select
                                    value={deliveryStatus}
                                    onChange={(e) => this.handleDeliveryStatus(e.target.value)}
                                    title="Situação da entrega."
                                    SelectProps={{
                                        native: true,
                                    }}>
                                    <option value={null}></option>
                                    {DeliveryStatus.ALL_DELIVERY.map((status, index) => (
                                        <option value={status.name}>{status.description}</option>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item md={12}>
                                <TextField
                                    label="Observação"
                                    value={observation}
                                    multiline
                                    onChange={(e) => this.handleObservation(e.target.value)}
                                    title="Observação."
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Grid>

                            <Grid item md={12}>
                                <StubUpdateButton
                                    stub={stub}
                                    showConfirmDialog={replaceImage}
                                    key={Math.random()}
                                    onUpdateSucceeded={onUpdateSucceeded}
                                    onUpdateFailed={onUpdateFailed} />
                            </Grid>

                            <Grid container>
                                <Grid item md={12}>
                                    Coletado em <b>{new moment(creationDateTimeOnApp).format('DD/MM/YYYY HH:mm:ss')}</b> / Upload em <b>{new moment(creationDate).format('DD/MM/YYYY HH:mm:ss')}</b><br />
                                </Grid>
                                {lastUpdateUser &&
                                    <Grid item md={12}>
                                        Última atualização em <b>{new moment(lastUpdateDateTime).format('DD/MM/YYYY HH:mm:ss')}</b> por <b>{lastUpdateUser}</b>
                                    </Grid>}
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </CardContent>
            </Fragment>
        );
    }

    handleDocumentType = (documentType) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    documentType: InputUtils.emptyToNull(documentType)
                }
            });
    }

    handleIssuerCnpjCpf = (issuerCnpjCpf) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    issuerCnpjCpf: InputUtils.emptyToNull(InputUtils.formatRemoveMask(issuerCnpjCpf))
                }
            });
    }

    handleSerie = (serie) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    serie: InputUtils.emptyToNull(InputUtils.onlyNumbers(serie))
                }
            });
    }

    handleNumber = (number) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    number: InputUtils.emptyToNull(InputUtils.onlyNumbers(number))
                }
            });
    }

    handleAccessKey = (accessKey) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    accessKey: InputUtils.emptyToNull(accessKey)
                }
            });
    }

    handleIssuerDate = (issuerDate) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    issuerDate: InputUtils.emptyToNull(InputUtils.formatDateByPattern(issuerDate, 'YYYY-MM-DD'))
                }
            });
    }

    handleReceiverName = (receiverName) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    receiverName: InputUtils.emptyToNull(receiverName)
                }
            });
    }

    handleReceiverCnpjCpf = (receiverCnpjCpf) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    receiverCnpjCpf: InputUtils.emptyToNull(InputUtils.formatRemoveMask(receiverCnpjCpf))
                }
            });
    }

    handleLatitude = (latitude) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    latitude: InputUtils.emptyToNull(latitude)
                }
            });
    }

    handleLongitude = (longitude) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    longitude: InputUtils.emptyToNull(longitude)
                }
            });
    }

    handleDeliveryStatus = (deliveryStatus) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    deliveryStatus: InputUtils.emptyToNull(deliveryStatus)
                }
            });
    }

    handleObservation = (observation) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    observation: InputUtils.emptyToNull(observation)
                }
            });
    }

    handleImage = (image, replaceImage, onUpdated) => {
        this.setState(
            {
                stub: {
                    ...this.state.stub,
                    image,
                    replaceImage
                }
            }, onUpdated);
    }
}

export default StubEditForm;