import { Button, IconButton, Input, Paper } from "@material-ui/core";
import React, { Component, Fragment } from "react";

class PhotoPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: null,
            onPicked: props.onPicked
        }
    }

    render() {

        return (
            <Fragment>
                <Input
                    type="file"
                    accept="image/jpeg"
                    onChange={(e) => { this.handleImageChange(e); }}
                    style={{ display: 'none' }}
                    id="upload-button" />

                <label htmlFor="upload-button" style={{margin: 0}}>
                    <Button 
                        variant="contained"
                        color="primary"
                        component="span">
                        Nova Foto
                    </Button>
                </label>
            </Fragment>
        );
    }

    handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'image/jpeg') {
            const reader = new FileReader();
            reader.onloadend = () => {
                const { onPicked } = this.state;
                const image = reader.result.split(',')[1];
                this.setState({ ...this.state, image }, () => onPicked(this.state.image));
            };
            reader.readAsDataURL(file);
        } else {
            alert('Por favor, selecione uma imagem no formato JPG.');
        }
    };
}

export default PhotoPicker;