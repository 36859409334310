import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import NotificationUtils from "../../../utils/notificationUtils";
import StubApi from "../../../api/stubApi";
import LoadingSpinner from "../../LoadingSpinner";
import zIndex from "@material-ui/core/styles/zIndex";

class StubUpdateButton extends Component {

    constructor(props) {
        super(props);

        const confirmState =
            props.showConfirmDialog ?
                this.withConfirm :
                this.withNoConfirm;

        this.state = {
            stub: props.stub,
            onUpdateSucceeded: props.onUpdateSucceeded,
            onUpdateFailed: props.onUpdateFailed,
            beforeUpdate: props.beforeUpdate,
            showLoader: false,
            ...confirmState,
        }
    }

    render() {

        const { showConfirmDialog, buttonAction, showLoader } = this.state;

        return (
            <Fragment>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={(e) => buttonAction()}>
                    Salvar
                </Button>

                <LoadingSpinner visible={showLoader} />

                {showConfirmDialog &&
                    <Dialog
                        open={showConfirmDialog}
                        onClose={() => this.closeDialog()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">

                        <DialogTitle id="alert-dialog-title">
                            Atenção
                        </DialogTitle>

                        <DialogContent style={{ fontSize: 16 }}>
                            Você está prestes a atualizar as informações do canhoto e <b style={{ color: 'red' }}>substituir a foto original</b>. Deseja prosseguir?
                        </DialogContent>

                        <DialogActions>
                            <Grid container spacing={1} direction="row" justifyContent="flex-end" alignItems="center">

                                <Grid item>
                                    <Button
                                        component="span"
                                        onClick={this.updateStub}>
                                        Sim
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Button
                                        component="span"
                                        onClick={this.closeDialog}>
                                        Não
                                    </Button>
                                </Grid>

                            </Grid>

                        </DialogActions>
                    </Dialog>
                }
            </Fragment>
        );
    }

    withConfirm = {
        buttonAction: () => { this.setState({ ...this.state, showConfirmDialog: true }) },
        showConfirmDialog: false
    }

    withNoConfirm = {
        buttonAction: () => { this.updateStub(); },
        showConfirmDialog: false
    }

    closeDialog = () => {
        this.setState({ ...this.state, showConfirmDialog: false });
    }

    updateStub = () => {
        this.setState({ ...this.state, showConfirmDialog: false, showLoader: true }, () => {
            const { stub, onUpdateSucceeded, onUpdateFailed } = this.state;
            StubApi
                .updateStub(stub.id, stub)
                .then(response => {
                    const message = response.message;
                    if (message.success) {
                        this.setState({ ...this.state, showLoader: false }, () => { onUpdateSucceeded(); NotificationUtils.success(message.details) });
                    } else {
                        this.setState({ ...this.state, showLoader: false }, () => { onUpdateFailed(); NotificationUtils.warn(message.details) });
                    }
                });
        });
    }
}

export default StubUpdateButton;