import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Paper } from "@material-ui/core";
import { Close, InsertPhoto } from "@material-ui/icons";
import React, { Component, Fragment } from "react";
import PhotoView from "./PhotoView";
import PhotoPicker from "./PhotoPicker";

class StubEditPhoto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            revert: false,
            image: props.stub.image,
            originalImage: props.stub.image,
            onPicked: props.onPicked,
            onReverted: props.onReverted,
        }
    }

    render() {

        const { open, revert, image } = this.state;

        return (
            <Fragment>
                <InsertPhoto
                    style={{ fontSize: 40, cursor: 'pointer' }}
                    titleAccess={'Foto do canhoto.'}
                    onClick={e => this.openDialog()} />

                {open &&
                    <Dialog
                        open={open}
                        onClose={() => this.closeDialog()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">

                        <DialogTitle id="alert-dialog-title">
                            Foto do Canhoto
                        </DialogTitle>

                        <DialogContent>
                            <Grid container md={12} justifyContent="flex-end" alignItems="center">
                                <PhotoView image={image} />
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Grid container spacing={1} direction="row" justifyContent="flex-end" alignItems="center">

                                {revert &&
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            component="span"
                                            color="primary"
                                            onClick={(e) => this.revertPhoto()}>
                                            Reverter
                                        </Button>
                                    </Grid>
                                }

                                <Grid item>
                                    <PhotoPicker
                                        onPicked={(image) => this.pickPhoto(image)}
                                    />
                                </Grid>

                                <Grid item>
                                    <Button
                                        component="span"
                                        onClick={this.closeDialog}>
                                        Voltar
                                    </Button>
                                </Grid>

                            </Grid>

                        </DialogActions>
                    </Dialog>
                }
            </Fragment>
        );
    }

    openDialog = () => {
        this.setState({ ...this.state, open: true });
    }

    closeDialog = () => {
        this.setState({ ...this.state, open: false });
    }

    pickPhoto = (image) => {
        const { onPicked } = this.state;
        this.setState({ ...this.state, revert: true, image }, () => onPicked(this.state.image));
    }

    revertPhoto = () => {
        const { originalImage, onReverted } = this.state;
        this.setState({ ...this.state, revert: false, image: originalImage }, () => onReverted(this.state.originalImage));
    }
}

export default StubEditPhoto;