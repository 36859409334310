class DocumentType {

  static NFE = new DocumentType('NFE', 'NF-e');
  static CTE = new DocumentType('CTE', 'CT-e');
  static NFCE = new DocumentType('NFCE', 'NFC-e');
  static CFESAT = new DocumentType('CFESAT', 'CF-e SAT');
  static OUTROS = new DocumentType('OUTROS', 'Outros');

  static ALL_DOCUMENTS = [ this.NFE, this.CTE, this.NFCE, this.CFESAT, this.OUTROS ];

  constructor(name, description) {
    this.name = name;
    this.description = description;
  }

}

export default DocumentType;